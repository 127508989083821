<template>
  <div class="settings-option d-flex align-items-center justify-content-between">
    <slot name="prepend" />
    <div class="d-flex align-items-center">
      <div class="icon-wrapper">
        <AppIcon
          :key="icon"
          is-img-tag
          :name="icon"
          size="20px"
        />
      </div>
      <div class="text-block d-flex flex-column">
        <div class="d-flex align-items-center">
          <AppText
            size="16px"
            :line-height="0.9"
            class="font-medium"
          >
            {{ title }}
          </AppText>
          <slot name="tooltip" />
        </div>
        <AppText v-if="description" opacity="0.4">
          {{ description }}
        </AppText>
      </div>
    </div>
    <div class="d-flex">
      <slot name="append" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'SettingsOption',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },

};
</script>

<style lang="scss" scoped>
.settings-option{
  height: 86px;

  box-shadow: inset 0px -1px 0px var(--color-black-007);
  .text-block{
    margin: 0 16px;

    :deep(.text:first-child){
      margin-bottom: 8px;
    }
  }
}

.icon-wrapper {
  background: #F0F0F0;
  padding: 10px;
  border-radius: 50%;

  @include flex-center;
}
</style>
