<template>
  <div class="rule" :class="{ child: isChild }">
    <div class="switch-wrapper">
      <FSwitch
        :model-value="isRuleOn"
        :type="switcherType"
        is-small
        :loading="isLoading"
        :disabled="disabled"
        @change="onSwitcherChange"
      />
    </div>

    <div class="info">
      <AppText
        variant="div"
        :size="isChild ? '13px' : '16px'"
        class="font-medium"
        :class="{ danger: isDanger && isRuleOn }"
        :opacity="disabled ? 0.4 : 1"
      >
        {{ title }}
      </AppText>
      <AppText
        variant="div"
        :line-height="1.5"
        :mt="isChild ? '6px' : '8px'"
        :opacity="disabled ? 0.4 : 0.6"
        style="max-width: 580px;"
      >
        {{ description }}
      </AppText>
    </div>

    <FSelect
      v-if="!isSelectHidden && type === 'select'"
      :model-value="propDuration"
      class="rule-select-wrapper"
      :options="selectOptions"
      option-value="value"
      option-text="text"
      has-arrow
      :disabled="disabled || !isRuleOn"
      size="x-small"
      @change="onSelectChange"
    >
      <template v-if="isDanger" #prefix>
        <AppIcon class="trash-icon" :class="{ disabled: disabled || !isRuleOn }" name="trash" size="18px" />
      </template>
    </FSelect>

    <div v-if="type === 'buttons'" class="rule-buttons-wrapper">
      <div
        v-for="option in selectOptions"
        :key="option.value"
        class="rule-buttons"
        tabindex="0"
        :class="{
          active: isRuleOn && option.value === propDuration,
          disabled: disabled || !isRuleOn,
        }"
        @click="onSelectChange(option.value)"
        @keypress.space="e => onSpacePress(e, option.value)"
      >
        <AppText>
          {{ option.text }}
        </AppText>
      </div>
    </div>

    <template v-if="type === 'slot'">
      <slot />
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'RulesItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    selectOptions: {
      type: Array,
      default: () => [],
    },
    isRuleOn: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: [String, Number],
      default: '',
    },
    isChild: {
      type: Boolean,
      default: false,
    },
    isSelectHidden: {
      type: Boolean,
      default: false,
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'select',
      validator: (value) => ['select', 'buttons', 'slot'].includes(value),
    },
  },
  emits: ['switcherChange', 'selectChange'],
  setup(props, { emit }) {
    const propDuration = computed(() => props.duration);

    const switcherType = computed(() => (props.isDanger ? 'danger' : 'primary'));

    const onSwitcherChange = (value) => {
      emit('switcherChange', value);
    };

    const onSelectChange = (value) => {
      emit('selectChange', value);
    };

    const onSpacePress = (e, value) => {
      e.preventDefault();
      onSelectChange(value);
    };

    return {
      propDuration,
      switcherType,
      onSwitcherChange,
      onSelectChange,
      onSpacePress,
    };
  },
};
</script>

<style lang="scss" scoped>
.rule {
  display: flex;
  gap: 30px;
  padding: 0;

  &.child {
    margin-top: 30px;
    margin-left: 12px;
    border-left: 3px solid #E6E6E6;
    padding: 0 0 0 45px;
    min-height: unset;
    align-self: center;
    align-items: center;

    .switch-wrapper {
      align-self: center;
    }

    .rule-select-wrapper {
      margin: unset;
    }
  }

  .info {
    flex-grow: 1;
  }

  .rule-select-wrapper {
    width: 195px;
  }

  .rule-buttons-wrapper {
    margin-right: 20px;
    display: grid;
    grid-template-columns: repeat(3, 50px);
    grid-template-rows: auto;
    grid-gap: 8px;

    .rule-buttons {
      border: 1px solid var(--color-black-01);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: var(--color-white);
      @include transition-base('background, border-color');
      @include focus-visible;

      &:hover:not(.disabled), &.active {
        background: #FBF3EC;
        border: 1px solid var(--color-primary);
      }

      &.disabled {
        cursor: not-allowed;
        background: #F2F2F2;
        border: 1px solid #E6E6E6;
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .danger {
    color: var(--color-error);
  }

  :deep(.el-switch) {
    height: 20px;
  }

  .trash-icon {
    :deep(path) {
      fill: var(--color-error);
    }

    &.disabled {
      :deep(path) {
        fill: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
