import { computed } from 'vue';

import { i18n } from '@/plugins/localization';

export const publicPaymentsAutoRevokeOptions = computed(() => {
  const { t } = i18n.global;
  return [
    { text: `30 ${t('period.second', 30)}`, value: 'THIRTY_SECONDS' },
    { text: `1 ${t('period.minute', 1)}`, value: 'MINUTE' },
    { text: `5 ${t('period.minute', 5)}`, value: 'FIVE_MINUTES' },
  ];
});

export const antiSpamOptions = computed(() => {
  const { t } = i18n.global;
  return [
    { text: `1 ${t('period.minute', 1)}`, value: 'MINUTE' },
    { text: `3 ${t('period.minute', 3)}`, value: 'THREE_MINUTES' },
    { text: `5 ${t('period.minute', 5)}`, value: 'FIVE_MINUTES' },
    { text: `10 ${t('period.minute', 10)}`, value: 'TEN_MINUTES' },
  ];
});

export const selfDestructOptions = computed(() => {
  const { t } = i18n.global;
  return [
    { text: `1 ${t('period.week', 1)}`, value: 'WEEK' },
    { text: `1 ${t('period.month', 1)}`, value: 'MONTH' },
    { text: `3 ${t('period.month', 3)}`, value: 'THREE_MONTHS' },
    { text: `6 ${t('period.month', 6)}`, value: 'SIX_MONTHS' },
  ];
});

export const slippageOptions = [
  { text: '1%', value: 1 },
  { text: '2%', value: 2 },
  { text: '3%', value: 3 },
  { text: '4%', value: 4 },
  { text: '5%', value: 5 },
];
