<template>
  <div class="header">
    <AppText
      variant="div"
      size="24px"
      class="title font-medium"
      mb="8px"
    >
      {{ title }}
    </AppText>
    <AppText
      variant="div"
      :opacity="periodAgo ? '0.5' : '0'"
      mb="20px"
    >
      <i18n-t keypath="merchantSettings.general.label.lastChanges">
        <template #periodAgo>
          {{ periodAgo }}
        </template>
      </i18n-t>
    </AppText>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

import { getPeriodAgo } from '@/utils/functions';
import { i18n } from '@/plugins/localization';

export default {
  name: 'SettingsHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    lastUpdated: {
      type: [Number, String],
      default: null,
    },
  },
  setup(props) {
    const { t } = i18n.global;
    const periodAgo = ref(getPeriodAgo(props.lastUpdated) || t('common.never'));

    watch(() => props.lastUpdated, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        periodAgo.value = getPeriodAgo(newVal) || t('common.never');
      }
    });

    return { periodAgo };
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #E6E6E6;
}
</style>
