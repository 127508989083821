<template>
  <RulesItem
    :is-rule-on="settings?.slippage?.enabled"
    :duration="settings?.slippage?.percentage"
    :select-options="slippageOptions"
    :title="t('merchantSettings.rules.slippage.title')"
    :description="t('merchantSettings.rules.slippage.description')"
    type="buttons"
    :is-loading="isLoading"
    @switcher-change="onSlippageToggle"
    @select-change="onSlippageChange"
  />
</template>

<script>
import { ref } from 'vue';

import { updateSlippageRules } from '@/api/merchant';

import { slippageOptions } from '../data';

import RulesItem from './RulesItem.vue';

export default {
  name: 'RulesSlippage',
  components: {
    RulesItem,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const onSlippageToggle = async (val) => {
      isLoading.value = true;
      await updateSlippageRules(val ? slippageOptions[0].value : null);
      isLoading.value = false;
    };

    const onSlippageChange = (val) => {
      if (props.settings.slippage.enabled) {
        updateSlippageRules(val);
      }
    };

    return {
      isLoading,
      slippageOptions,
      onSlippageToggle,
      onSlippageChange,
    };
  },
};
</script>

<style scoped lang="scss"></style>
