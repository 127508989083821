import { useFetch } from '@/composables/useFetch';

export const revokeDevices = async () => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'delete',
    url: '/merchants/devices',
  });

  return { data, isSuccess };
};

export const revokeDeviceById = async (deviceId) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'delete',
    url: `/merchants/device/${deviceId}`,
  });

  return { data, isSuccess };
};
