<template>
  <div>
    <div v-if="currentDevice" style="margin-bottom: 40px;">
      <AppText
        class="font-medium"
        size="16px"
        mb="16px"
        variant="div"
      >
        {{ t('merchantSettings.sessions.currentSession') }}
      </AppText>
      <SessionItem
        :key="otherDevices.length"
        is-current-session
        :item="currentDevice"
        :has-more-sessions="otherDevices?.length >= 1"
        @get-data="onGetData"
      />
    </div>

    <div v-if="otherDevices?.length >= 1">
      <div style="margin-bottom: 20px;">
        <AppText class="font-medium" size="16px">
          {{ t('merchantSettings.sessions.otherSessions') }}
        </AppText>
        <AppText>
          ({{ otherDevices.length }})
        </AppText>
      </div>

      <SessionItem
        v-for="device in otherDevices"
        :key="device._id"
        :item="device"
        has-more-sessions
        @get-data="onGetData"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';

import { useSystemStore } from '@/store';
import { useProfile } from '@/composables/useProfile';

import SessionItem from './SessionItem.vue';

export default defineComponent({
  name: 'SettingsSessions',
  components: {
    SessionItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['get-data'],
  setup(props, { emit }) {
    const systemStore = useSystemStore();
    const storedDeviceId = systemStore.getDeviceId;
    const { profileId } = useProfile();

    const ID = computed(() => `${profileId.value}-${storedDeviceId}`);

    // eslint-disable-next-line
    const currentDevice = computed(() => props.items?.find((el) => el._id === ID.value));
    // eslint-disable-next-line
    const otherDevices = computed(() => props.items.filter((el) => el._id !== ID.value));

    const onGetData = () => {
      emit('get-data');
    };

    return {
      otherDevices,
      currentDevice,

      onGetData,
    };
  },
});
</script>
