<template>
  <RulesItem
    :is-rule-on="publicPaymentAutoRevokeEnabled"
    :duration="publicPaymentAutoRevokeDuration"
    :title="t('merchantSettings.rules.revoke.title')"
    :description="t('merchantSettings.rules.revoke.description')"
    :select-options="publicPaymentsAutoRevokeOptions"
    :is-loading="publicPaymentAutoRevokeLoading"
    @switcher-change="onPublicPaymentAutoRevokeStatusChange"
    @select-change="onPublicPaymentAutoRevokeDurationChange"
  />
  <RulesItem
    :is-rule-on="paymentAutoRevokeEnabled"
    :title="t('merchantSettings.rules.api.title')"
    :description="t('merchantSettings.rules.api.description')"
    is-child
    is-select-hidden
    :disabled="isPPControlDisabled"
    :is-loading="isPPControlLoading"
    @switcher-change="onPaymentAutoRevokeStatusChange"
  />
</template>

<script>
import { computed, ref } from 'vue';

import { publicPaymentsAutoRevokeOptions } from '../data';
import { updateRevokeSettings } from '../../../api';

import RulesItem from './RulesItem.vue';

export default {
  name: 'RulesAutoRevoke',
  components: {
    RulesItem,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // PublicPaymentsAutoRevoke
    const isPPControlDisabled = computed(() => !publicPaymentAutoRevokeEnabled.value);

    const publicPaymentAutoRevokeEnabled = computed(() => props.settings?.publicPaymentAutoRevoke?.enabled);
    const publicPaymentAutoRevokeDuration = computed(() => props.settings?.publicPaymentAutoRevoke?.duration || publicPaymentsAutoRevokeOptions.value[0].value);
    const publicPaymentAutoRevokeLoading = ref(false);

    const onPublicPaymentAutoRevokeStatusChange = async (val) => {
      publicPaymentAutoRevokeLoading.value = true;
      await updateRevokeSettings({
        publicPaymentAutoRevokeDuration: val ? publicPaymentAutoRevokeDuration.value : null,
      });
      publicPaymentAutoRevokeLoading.value = false;
    };

    const onPublicPaymentAutoRevokeDurationChange = (val) => {
      if (publicPaymentAutoRevokeEnabled.value) {
        updateRevokeSettings({
          publicPaymentAutoRevokeDuration: val,
        });
      }
    };

    // PaymentAutoRevoke
    const paymentAutoRevokeEnabled = computed(() => props.settings?.publicPaymentAutoRevoke?.apiPaymentAutoRevoke);
    const isPPControlLoading = ref(false);

    const onPaymentAutoRevokeStatusChange = async (apiPaymentAutoRevoke) => {
      isPPControlLoading.value = true;
      await updateRevokeSettings({ apiPaymentAutoRevoke });
      isPPControlLoading.value = false;
    };

    return {
      paymentAutoRevokeEnabled,
      publicPaymentAutoRevokeLoading,
      onPaymentAutoRevokeStatusChange,

      isPPControlDisabled,
      publicPaymentAutoRevokeEnabled,
      publicPaymentAutoRevokeDuration,
      publicPaymentsAutoRevokeOptions,
      isPPControlLoading,
      onPublicPaymentAutoRevokeStatusChange,
      onPublicPaymentAutoRevokeDurationChange,
    };
  },
};
</script>
