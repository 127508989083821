<template>
  <RulesItem
    :is-rule-on="antiSpamEnabled"
    :duration="antiSpamDuration"
    :title="t('merchantSettings.rules.antispam.title')"
    :description="t('merchantSettings.rules.antispam.description')"
    :select-options="antiSpamOptions"
    :is-loading="isAntispamLoading"
    @switcher-change="onAntiSpamStatusChange"
    @select-change="onAntiSpamDurationChange"
  />

  <RulesItem
    :is-rule-on="captcha"
    :title="t('merchantSettings.rules.captcha.title')"
    :description="t('merchantSettings.rules.captcha.description')"
    is-child
    is-select-hidden
    :is-loading="isCaptchaLoading"
    :disabled="isCaptchaControlDisabled"
    @switcher-change="onUpdateCaptcha"
  />
</template>

<script>
import { computed, ref } from 'vue';

import { updateMerchantRules } from '@/api/merchant';

import { antiSpamOptions } from '../data';

import RulesItem from './RulesItem.vue';

export default {
  name: 'RulesAntiSpam',
  components: {
    RulesItem,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // AntiSpam
    const antiSpamEnabled = computed(() => props.settings?.antiSpam?.enabled);
    const antiSpamDuration = computed(() => props.settings?.antiSpam?.duration || antiSpamOptions.value[0].value);
    const isAntispamLoading = ref(false);

    const onAntiSpamStatusChange = async (val) => {
      isAntispamLoading.value = true;
      await updateMerchantRules({
        antiSpamDuration: val ? antiSpamDuration.value : null,
      });
      isAntispamLoading.value = false;
    };

    const onAntiSpamDurationChange = async (val) => {
      if (antiSpamEnabled.value) {
        await updateMerchantRules({
          antiSpamDuration: val,
        });
      }
    };

    // Captcha
    const isCaptchaControlDisabled = computed(() => !antiSpamEnabled.value);
    const captcha = computed(() => props.settings.captcha);
    const isCaptchaLoading = ref(false);

    const onUpdateCaptcha = async (val) => {
      if (!isCaptchaControlDisabled.value) {
        isCaptchaLoading.value = true;
        await updateMerchantRules({
          captcha: val,
        });
        isCaptchaLoading.value = false;
      }
    };

    return {
      antiSpamEnabled,
      antiSpamDuration,
      antiSpamOptions,
      isAntispamLoading,
      onAntiSpamStatusChange,
      onAntiSpamDurationChange,

      isCaptchaControlDisabled,
      captcha,
      isCaptchaLoading,
      onUpdateCaptcha,
    };
  },
};
</script>
