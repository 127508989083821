<template>
  <RulesItem
    :is-rule-on="selfDestructEnabled"
    :duration="selfDestructDuration"
    :title="t('merchantSettings.rules.desctruct.title')"
    :description="t('merchantSettings.rules.desctruct.description')"
    :select-options="selfDestructOptions"
    is-danger
    :is-loading="selfDestructLoading"
    @switcher-change="onSelfDestructStatusChange"
    @select-change="onSelfDestructDurationChange"
  />

  <RulesItem
    :is-rule-on="detonatorEnabled"
    :title="t('merchantSettings.rules.detonator.title')"
    :description="t('merchantSettings.rules.detonator.description')"
    is-child
    is-select-hidden
    type="slot"
    :is-loading="detonatorLoading"
    :disabled="!selfDestructEnabled"
    @switcher-change="onDetonatorStatusChange"
  >
    <transition name="show">
      <AppText v-if="detonatorEnabled" class="detonator-tip font-medium">
        {{ t('merchantSettings.rules.detonator.codeSet') }}
      </AppText>
    </transition>
  </RulesItem>

  <ModalToEnableDetonator v-model="isDetonatorModalOpen" />
</template>

<script>
import { computed, ref } from 'vue';

import { disableDetonator, updateMerchantRules } from '@/api/merchant';

import { selfDestructOptions } from '../data';

import ModalToEnableDetonator from './ModalToEnableDetonator.vue';
import RulesItem from './RulesItem.vue';

export default {
  name: 'RulesSelfDestruct',
  components: {
    RulesItem,
    ModalToEnableDetonator,
  },
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // SelfDestruct
    const selfDestructEnabled = computed(() => props.settings?.selfDestruct?.enabled);
    const selfDestructDuration = computed(() => props.settings?.selfDestruct?.period || selfDestructOptions.value[0].value);
    const selfDestructLoading = ref(false);

    const onSelfDestructStatusChange = async (val) => {
      selfDestructLoading.value = true;
      await updateMerchantRules({
        selfDestruct: val ? selfDestructDuration.value : null,
      });
      selfDestructLoading.value = false;
    };

    const onSelfDestructDurationChange = (val) => {
      if (selfDestructEnabled.value) {
        updateMerchantRules({
          selfDestruct: val,
        });
      }
    };

    // Detonator
    const isDetonatorModalOpen = ref(false);
    const detonatorEnabled = computed(() => props.settings?.isDetonationCodeSet);
    const detonatorLoading = ref(false);

    const onDetonatorStatusChange = async (val) => {
      if (val) {
        isDetonatorModalOpen.value = true;
      } else {
        detonatorLoading.value = true;
        await disableDetonator();
        detonatorLoading.value = false;
      }
    };

    return {
      selfDestructEnabled,
      selfDestructDuration,
      selfDestructOptions,
      selfDestructLoading,
      onSelfDestructStatusChange,
      onSelfDestructDurationChange,

      isDetonatorModalOpen,
      detonatorEnabled,
      detonatorLoading,
      onDetonatorStatusChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.detonator-tip {
  background-color: var(--color-error-01);
  color: var(--color-8E6464C6);
  height: 36px;
  border-radius: 54px;
  min-width: 150px;
  @include flex-center;
}
</style>
