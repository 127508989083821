<template>
  <div class="session-item" :class="{ 'on-waiting': isLoading }">
    <CountryFlag
      v-if="item?.countryCode"
      :value="item?.countryCode"
      size="32px"
      style="margin-right: 30px;"
    />
    <CustomFlag
      v-if="item.ip === '127.9.9.9'"
      name="flag-tor"
      style="margin-right: 30px;"
    />
    <div>
      <AppText
        size="14px"
        class="font-medium"
        variant="div"
        mb="8px"
      >
        {{ title }}
      </AppText>
      <div class="device">
        <AppIcon
          v-if="deviceIcon"
          :key="deviceIcon"
          :name="deviceIcon"
          is-img-tag
          size="14px"
        />
        <AppText>
          {{ device }}
        </AppText>
      </div>
    </div>

    <FSpacer />

    <DateFormatter color="#666" :date="item?.signinAt" :format="dateTimeFormat" />

    <FButton
      v-if="hasMoreSessions"
      type="text"
      size="small"
      no-margin
      :disabled="isLoading"
      style="margin-left: 30px;"
      @click="onClick"
    >
      <template v-if="isCurrentSession">
        {{ t('merchantSettings.sessions.killAllSessions') }}
      </template>
      <template v-else>
        {{ t('merchantSettings.sessions.killSession', { number: item.sid }) }}
      </template>
    </FButton>
  </div>
</template>

<script setup>
import 'flag-icons/css/flag-icons.min.css';
import { computed, ref } from 'vue';

import CountryFlag from '@/components/Icon/CountryFlag.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import CustomFlag from '@/components/Icon/CustomFlag.vue';

import { useProfile } from '@/composables/useProfile';
import { i18n } from '@/plugins/localization';

import { revokeDeviceById, revokeDevices } from './api';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  isCurrentSession: {
    type: Boolean,
    default: false,
  },
  hasMoreSessions: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['get-data']);

const title = computed(() => {
  if (props.item.ip === '127.9.9.9') {
    const { t } = i18n.global;
    return t('merchantSettings.sessions.tor');
  }
  const place = props.item.city && props.item.country ? `(${props.item.city}, ${props.item.country})` : '';
  return props.item && `${props.item.ip} ${place}`;
});
const device = computed(() => props.item && `${props.item.deviceName} ${props.item.deviceOs ? `on ${props.item.deviceOs}` : ''}`);

const deviceIcon = computed(() => {
  if (['smartphone', 'phone', 'tablet', 'phablet', 'feature phone'].includes(props.item?.deviceType)) {
    return 'device-phone';
  }
  if (['desktop'].includes(props.item?.deviceType)) {
    return 'device-desktop';
  }
  return null;
});

const { dateTimeFormat } = useProfile();

const isLoading = ref(false);
const onClick = async () => {
  const isResponseSuccess = ref(false);
  isLoading.value = true;

  if (props.isCurrentSession) {
    const { isSuccess } = await revokeDevices();
    isResponseSuccess.value = isSuccess;
  } else {
    // eslint-disable-next-line
    const { isSuccess } = await revokeDeviceById(props.item._id);
    isResponseSuccess.value = isSuccess;
  }

  if (isResponseSuccess.value) {
    emit('get-data');
  }
};
</script>

<style scoped lang="scss">
.session-item {
  border: 1px solid var(--color-black-01);
  border-radius: 8px;

  padding: 25px 30px 25px 20px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;

  opacity: 1;
  @include transition-base(opacity);

  &.on-waiting {
    opacity: 0.4;
  }
}

.device {
  height: 16px;
  display: flex;
  align-items: center;

  :deep(.icon) {
    margin-right: 6px;
  }
}

:deep(.el-button--text:hover) {
  background: var(--color-error) !important;
  color: white;
  border-color: transparent;
}

:deep(.el-button.is-disabled) {
  border: 1px solid var(--color-black-005)
}
</style>
